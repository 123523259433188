/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "../../../../components/MKSocialButton";
import { useTranslation } from "react-i18next";
// import Card from "@mui/material/Card";

function Footer() {
  const { t } = useTranslation();

  return (
    // <Card
    //   sx={{
    //     p: 2,
    //     mx: { xs: 2, lg: 3 },
    //     mt: -8,
    //     mb: 4,
    //     backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
    //     backdropFilter: "saturate(200%) blur(30px)",
    //     boxShadow: ({ boxShadows: { xxl } }) => xxl,
    //   }}
    // >
    <MKBox pt={18} pb={6}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
            <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
              {t("presentation.thankyou")}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              {t("presentation.bestexperience")}
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            my={{ xs: 5, lg: "auto" }}
            mr={{ xs: 0, lg: "auto" }}
            sx={{ textAlign: { xs: "center", lg: "right" } }}
          >
            <MKSocialButton
              component="a"
              href="https://twitter.com/intent/tweet?text=Check%20the%20TripEnhancer%20App%23mui5&amp;url=https%3A%2F%2Fwww.tripenhancer.app"
              target="_blank"
              color="twitter"
              sx={{ mr: 1 }}
            >
              <i className="fab fa-twitter" />
              &nbsp;Tweet
            </MKSocialButton>
            <MKSocialButton
              component="a"
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.tripenhancer.app"
              target="_blank"
              color="facebook"
              sx={{ mr: 1 }}
            >
              <i className="fab fa-facebook" />
              &nbsp;Share
            </MKSocialButton>
            <MKSocialButton
              component="a"
              href="https://www.pinterest.com/pin/create/button/?url=https://www.tripenhancer.app"
              target="_blank"
              color="pinterest"
            >
              <i className="fab fa-pinterest" />
              &nbsp;Pin it
            </MKSocialButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    // </Card>
  );
}

export default Footer;
