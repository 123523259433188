/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import deleteaccountImage from "assets/images/deleteaccount.jpg";

//Google analytics
import ReactGA from "react-ga4";
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

function DeleteYourAccount() {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "DeleteYourAccount",
  });

  return (
    <>
      {
        <MKBox position="fixed" top="0.5rem" width="100%">
          <DefaultNavbar
            routes={routes}
            action={{
              type: "external",
              route: "https://play.google.com/store/apps/details?id=com.naxx.tripenhancer.free",
              label: "Get the App",
              color: "info",
            }}
          />
        </MKBox>
      }
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MKTypography variant="h3" color="white">
                  How to Delete Your Account
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  To delete your TripEnhancer account, just follow the steps below. If you have any
                  problem, send an email to tripenhancerapp@gmail.com.
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <MKBox mb={1}>
                <HorizontalTeamCard
                  image={deleteaccountImage}
                  name="Navigate to account overview"
                  position={{ color: "info", label: "1. Account overview" }}
                  description="To delete your TripEnhancer account, you first have to open the app and tap on the Authentication
                  icon in the top right corner to navigate to the Account overview page."
                />
              </MKBox>
              <MKBox mb={1}>
                <HorizontalTeamCard
                  image={deleteaccountImage}
                  name="Delete your account"
                  position={{ color: "info", label: "2. Delete Account" }}
                  description="To effectively delete your TripEnhancer account, scroll down to the bottom of the account overview page and tap
                  on the Delete Account button."
                />
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default DeleteYourAccount;
