/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Shop page sections
import Footer from "pages/LandingPages/Shop/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";

import { Helmet } from "react-helmet";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import footerRoutes from "../../../footer.routes";

function Shop() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.tripenhancer.app/",
          label: "Free Download",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div id="shop">Loading...</div>
          <ShopScript />
          <Footer />
        </Card>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

/*function ShopScript() {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.text =
      " {var spread_shop_config = {prefix: 'https://dorpskado.myspreadshop.be',shopName: 'DorpsKado',baseId: 'shop',};`}";
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src = "https://dorpskado.myspreadshop.be/js/shopclient.nocache.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return null;
}*/
function ShopScript() {
  return (
    <>
      <Helmet>
        <script>
          {`var spread_shop_config = {
                prefix: 'https://dorpskado.myspreadshop.be',
                shopName: 'dorpskado',
                baseId: 'shop',
                /*    locale: 'de_DE',
    startToken: 'men+t-shirts?q=P24',
    usePushState: false,
    updateMetadata: true,*/
            };`}
        </script>
        <script
          type="text/javascript"
          src="https://dorpskado.myspreadshop.be/js/shopclient.nocache.js"
        ></script>
      </Helmet>
    </>
  );
}
export default Shop;
